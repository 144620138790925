<script>
import {mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters({
            authenticated: 'auth/authenticated',
            transLoading: 'trans/loading',
            user: 'user/authenticated',
            authLoading: 'auth/loading'
        }),

        loading: function () {
            return this.transLoading || this.authLoading;
        },
    },

    data: function () {
        return {
            credentials: {
                email: null,
                password: null,
                remember: null
            },
            password_type: 'password'
        }
    },

    methods: {
        login: function () {
            this.$store.dispatch('auth/login', this.credentials).then(() => {
                if (this.authenticated) {
                    // this.$router.push(this.$t('routes./profiles/:id(\\d+)', {id: this.user?.id}).rmValidation());
                } else {
                    // console.log(response)
                }
            });
        },

        togglePasswordType: function () {
            this.password_type = this.password_type === 'password' ? 'text' : 'password'
        },
    },

    watch: {
        'user': {
            deep: true,
            handler: function (user) {
                if (user?.id) {
                    this.$router.push(this.$t('routes./profiles/:id(\\d+)', {id: user.id}).rmValidation());
                }
            },
        }
    }
}
</script>

<template>
    <div class="auth-page">
        <b-overlay :show="loading" variant="dark">
            <div class="container-fluid p-0">
                <div class="row g-0 align-items-center">
                    <div class="col-xxl-4 col-lg-4 col-md-6">
                        <div class="row justify-content-center g-0">
                            <div class="col-xl-9">
                                <div class="p-4">
                                    <div class="card mb-0">
                                        <div class="card-body">
                                            <div class="auth-full-page-content rounded d-flex p-3 my-2">
                                                <div class="w-100">
                                                    <div class="d-flex flex-column h-100">
                                                        <div class="mb-4 mb-md-5">
                                                            <router-link to="/" class="d-block auth-logo">
                                                                <img src="@/assets/images/logo-dark.png" alt="" height="22" class="auth-logo-dark me-start">
                                                                <img src="@/assets/images/logo-light.png" alt="" height="22" class="auth-logo-light me-start">
                                                            </router-link>
                                                        </div>
                                                        <div class="auth-content my-auto">
                                                            <div class="text-center">
                                                                <h5 class="mb-0">{{ $t('auth.welcome_back').ucFirst() }}</h5>
                                                                <p class="text-muted mt-2">{{ $t('auth.sign_in_to_continue_to').ucFirst() }} aaCRM.</p>
                                                            </div>
                                                            <form class="mt-4 pt-2">
                                                                <div class="form-floating form-floating-custom mb-4">
                                                                    <input v-model="credentials.email" type="text" class="form-control" id="input-username" :placeholder="$t('auth.enter_user_name').ucFirst()">
                                                                    <label for="input-username">{{ $t('auth.username').ucFirst() }}</label>
                                                                    <div class="form-floating-icon">
                                                                        <eva-icon name="people-outline"></eva-icon>
                                                                    </div>
                                                                </div>

                                                                <div class="form-floating form-floating-custom mb-4 auth-pass-inputgroup">
                                                                    <input v-model="credentials.password" :type="password_type" class="form-control pe-5" id="password-input" :placeholder="$t('auth.enter_password').ucFirst()">

                                                                    <button @click.prevent="togglePasswordType" type="button" class="btn btn-link position-absolute h-100 end-0 top-0" id="password-addon">
                                                                        <i class="mdi mdi-eye-outline font-size-18 text-muted"></i>
                                                                    </button>
                                                                    <label for="password-input">{{ $t('auth.password').ucFirst() }}</label>
                                                                    <div class="form-floating-icon">
                                                                        <eva-icon name="lock-outline"></eva-icon>
                                                                    </div>
                                                                </div>

                                                                <div v-if="false" class="row mb-4">
                                                                    <div class="col">
                                                                        <div class="form-check font-size-15">
                                                                            <input v-model="credentials.remember" class="form-check-input" type="checkbox" id="remember-check">
                                                                            <label class="form-check-label font-size-13" for="remember-check">
                                                                                {{ $t('auth.remember_me').ucFirst() }}
                                                                            </label>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div class="mb-3">
                                                                    <button @click.prevent="login" class="btn btn-primary w-100 waves-effect waves-light" type="submit">{{ $t('auth.log_in').ucFirst() }}</button>
                                                                </div>
                                                            </form>
<!--
                                                            <div class="mt-4 pt-3 text-center">
                                                                <div class="signin-other-title">
                                                                    <h5 class="font-size-14 mb-4 text-muted fw-medium">- Or you can join with -</h5>
                                                                </div>

                                                                <div class="d-flex gap-2">
                                                                    <button type="button" class="btn btn-soft-primary waves-effect waves-light w-100">
                                                                        <i class="bx bxl-facebook font-size-16 align-middle"></i>
                                                                    </button>
                                                                    <button type="button" class="btn btn-soft-info waves-effect waves-light w-100">
                                                                        <i class="bx bxl-linkedin font-size-16 align-middle"></i>
                                                                    </button>
                                                                    <button type="button" class="btn btn-soft-danger waves-effect waves-light w-100">
                                                                        <i class="bx bxl-google font-size-16 align-middle"></i>
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <div class="mt-4 pt-3 text-center">
                                                                <p class="text-muted mb-0">Don't have an account ? <router-link to="/auth/register"
                                                                    class="text-primary fw-semibold"> Signup now </router-link> </p>
                                                            </div>
-->
                                                        </div>
                                                        <div class="mt-4 text-center">
                                                            <p class="mb-0">© {{new Date().getFullYear()}} aaCRM   . Crafted with <i class="mdi mdi-heart text-danger"></i> by Appon</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end auth full page content -->
                    </div>
                    <!-- end col -->
                    <div class="col-xxl-8 col-lg-8 col-md-6">
                        <div class="auth-bg bg-white py-md-5 p-4 d-flex">
                            <div class="bg-overlay bg-white"></div>
                            <!-- end bubble effect -->
                            <div class="row justify-content-center align-items-center">
                                <div class="col-xl-8">
                                    <div class="mt-4">
                                        <img src="@/assets/images/login-img.png" class="img-fluid" alt="">
                                    </div>
                                    <div class="p-0 p-sm-4 px-xl-0 py-5">
                                        <div id="reviewcarouselIndicators" class="carousel slide auth-carousel" data-bs-ride="carousel">
                                            <div class="carousel-indicators carousel-indicators-rounded">
                                                <button type="button" data-bs-target="#reviewcarouselIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
<!--
                                                <button type="button" data-bs-target="#reviewcarouselIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                                <button type="button" data-bs-target="#reviewcarouselIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
-->
                                            </div>

                                            <!-- end carouselIndicators -->
                                            <div class="carousel-inner w-75 mx-auto">
                                                <div class="carousel-item active">
                                                    <div class="testi-contain text-center">
                                                        <h5 class="font-size-20 mt-4">{{ $t('auth.slide_1_title').ucFirst() }}</h5>
                                                        <p class="font-size-15 text-muted mt-3 mb-0">{{ $t('auth.slide_1_content').ucFirst() }}</p>
                                                    </div>
                                                </div>
<!--
                                                <div class="carousel-item">
                                                    <div class="testi-contain text-center">
                                                        <h5 class="font-size-20 mt-4">“Our task must be to
                                                            free widening our circle”</h5>
                                                        <p class="font-size-15 text-muted mt-3 mb-0">
                                                            Curabitur eget nulla eget augue dignissim condintum Nunc imperdiet ligula porttitor commodo elementum
                                                            Vivamus justo risus fringilla suscipit faucibus orci luctus
                                                            ultrices posuere cubilia curae lectus non ultricies cursus.
                                                        </p>
                                                    </div>
                                                </div>

                                                <div class="carousel-item">
                                                    <div class="testi-contain text-center">
                                                        <h5 class="font-size-20 mt-4">“I've learned that
                                                            people will forget what you”</h5>
                                                        <p class="font-size-15 text-muted mt-3 mb-0">
                                                            Pellentesque lacinia scelerisque arcu in aliquam augue molestie rutrum magna Fusce dignissim dolor id auctor accumsan
                                                            vehicula dolor
                                                            vivamus feugiat odio erat sed vehicula lorem tempor quis Donec nec scelerisque magna
                                                        </p>
                                                    </div>
                                                </div>
-->
                                            </div>
                                            <!-- end carousel-inner -->
                                        </div>
                                        <!-- end review carousel -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end col -->
                </div>
                <!-- end row -->
            </div>
            <!-- end container fluid -->
        </b-overlay>
    </div>
</template>
